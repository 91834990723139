<template>
   <div class="course" style="height:100%;font-size:0">
    <iframe ref="myIframe" :src="iframeUrl" frameborder="0" scrolling="auto" width="100%" style="height:calc(100vh - 72px);"></iframe>
  </div>
</template>
<script>
	import {queryUsers} from '@/assets/js/queryUsers.js';
	import {mapState} from "vuex";

	export default {
		name:'medal',
		data(){
			return{
				iframeUrl:''
			}
		},
		computed: {
			...mapState({
				proToken: state => state.indexStore.B_PRO_TOKEN,
			})
		},
		created(){
			// 获取用户信息，如果有加载模块页面， 没有跳转登录页
			if(this.proToken) {
				this.setIframeUrl();
			}else{
				this.$router.push({
					path: "/VclassesCenterLogin",
					query: {
						navtype: 'medal',
					}
				})
			}
	
		},
		methods:{
			setIframeUrl(){
				const [agr,site]=window.location.href.split('://');
				let url=process.env.VUE_APP_MEDAL_URL;
				let params=process.env.VUE_APP_IFRAME_PARAMS +'&PAGE_URL='+agr+'://'+window.location.host+'&RECORD=3';
				this.iframeUrl=url+'?'+params
			}
		}
	}
</script>